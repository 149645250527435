<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'

  export default {
    props: {
      /** Whether the request is in the process of being submitted. If true, a spinner will be displayed. */
      submitting: {
        type: Boolean,
        default: false,
      },
      /** Error message if any. */
      error: {
        type: String,
        default: null,
      },
      /** SubscriptionId prop taken from route params. */
      subscriptionId: {
        type: Number,
        default: undefined,
      },
      /** Whether the user is upgrading to annual or alltruePlus. */
      type: {
        type: String,
        default: 'annual',
        validator: (value) => ['annual', 'alltruePlus'].includes(value),
      },
    },
    computed: {
      ...get('subscribe', ['alltruePlusSeasonalUpgradeCost']),
      ...get('customize', ['upgradeSeasons', 'upgradeCost']),
      screen,
      title() {
        switch (this.type) {
          case 'alltruePlus':
            return 'Alltrue+'
          default:
            return 'Annual Membership'
        }
      },
      featureList() {
        switch (this.type) {
          case 'alltruePlus':
            return [
              'Bonus product (worth $30+) in every box',
              'Customize your entire box',
              'Exclusive Alltrue+ members only T&#8209;shirt <br> <div class="text-3xs">(one-time Alltrue+ welcome gift)</div>',
              '$40 in market credits /year <br> <div class="text-3xs">($10/season)</div>',
            ]
          default:
            return ['Save $20 each year', 'Customize products', 'Early access to features']
        }
      },
      buttonColor() {
        switch (this.type) {
          case 'alltruePlus':
            return 'spring'
          default:
            return 'summer'
        }
      },
    },
  }
</script>

<template>
  <BaseModal open dismissible size="md" bg-color="dawn" @dismiss="$emit('dismiss')">
    <!-- Error handling -->
    <BasePanelWarning v-if="error" class="my-2">
      {{ error }}
    </BasePanelWarning>
    <!-- Header/title -->
    <div class="px-4 flex flex-col items-center">
      <h1 class="font-heading text-center leading-tight">
        <span class="text-2xl">Upgrade to</span> <br /><span
          class="font-semibold text-7xl leading-tighter"
        >
          {{ title }}
        </span>
      </h1>
      <!-- Bullet points of features -->
      <div class="mt-3 mb-7 flex flex-col">
        <div v-for="(feature, featureIndex) in featureList" :key="featureIndex" class="mb-1 flex">
          <BaseIcon
            type="icon"
            label="checkmark"
            :size="4"
            :class="`${type === 'annual' ? 'text-summer' : ''}`"
          >
            <IconCheckmarkWide />
          </BaseIcon>
          <BaseRichText :font-size="screen.md ? 'sm' : 'xs'" class="ml-3" v-html="feature" />
        </div>
      </div>
      <!-- Terms & conditions -->
      <p class="mb-6 text-3xs">
        <BaseDivider color="black" class="mb-2" />
        <template v-if="type === 'annual'">
          By clicking “Confirm & Upgrade”, you authorize us to terminate your existing quarterly
          subscription and charge you {{ upgradeCost(subscriptionId) }} for the next
          {{ upgradeSeasons(subscriptionId) }} quarters on your new annual subscription. As soon as
          the upgrade process completes, you'll see a newly created annual plan in the "My
          Subscriptions" section of your account portal. Additional shipping and handling costs
          apply for boxes shipping to Canada, AK, HI, and US Territories. Applicable sales taxes
          will be applied upon submission. If you have any questions, please review this FAQ or
          reach out to our customer care team at:
          <BaseLinkStyled class="font-medium" href="mailto:help@alltrue.com">
            help@alltrue.com
          </BaseLinkStyled>
        </template>
        <template v-else-if="type === 'alltruePlus'">
          By clicking "Confirm & Upgrade Now", you authorize us to charge you the equivalent of
          {{ $formatPrice(alltruePlusSeasonalUpgradeCost) }}/season, plus tax if applicable, for the
          remaining quarterly seasons in your Membership (for example, if you have two seasons
          remaining on your current Annual Membership you will be charged
          {{ $formatPrice(alltruePlusSeasonalUpgradeCost * 2) }} immediately upon upgrade). Your
          upgrade to Alltrue+ will renew automatically at your next renewal and annually thereafter
          for {{ $formatPrice(alltruePlusSeasonalUpgradeCost * 4) }} along with your Membership at
          the full regular price using the payment method associated with your account, plus any
          applicable shipping charges and taxes. To prevent renewal, you can cancel in your account
          membership page, or by emailing us at
          <BaseLinkStyled class="font-medium" href="mailto:help@alltrue.com">
            help@alltrue.com </BaseLinkStyled
          >. All prices listed are in USD.
        </template>
      </p>
      <!-- CTA -->
      <div class="w-full">
        <BaseButton :color="buttonColor" :disabled="submitting" @click="$emit('upgrade')">
          <span class="font-semibold">Confirm & Upgrade Now</span>
        </BaseButton>
      </div>
      <BaseLinkStyled color="black" class="mt-6 text-xs text-center" @click="$emit('dismiss')">
        No thanks, maybe later
      </BaseLinkStyled>
    </div>

    <!-- Loading spinner -->
    <BaseSpinner v-if="submitting" overlay="absolute" overlay-color="dawn-lt3" class="z-10" />
  </BaseModal>
</template>
