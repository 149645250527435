<script>
  export default {
    props: {
      /** Whether the user is upgrading to annual or alltruePlus. */
      type: {
        type: String,
        default: 'annual',
        validator: (value) => ['annual', 'alltruePlus'].includes(value),
      },
    },
  }
</script>

<template>
  <BaseModal
    open
    :size="type === 'alltruePlus' ? 'md' : 'xs'"
    bg-color="dawn"
    class="flex flex-col items-center text-center"
  >
    <div class="py-12">
      <h2 class="font-heading font-medium text-4xl leading-none"> You have been upgraded! </h2>
      <div v-if="type === 'alltruePlus'" class="mt-3 mb-6">
        You've got VIP written all over you.
      </div>
      <img src="/static/illustrations/IllustrationAnnualUpsell.png" class="m-auto" alt="" />
    </div>
  </BaseModal>
</template>
