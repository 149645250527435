<script>
  import { get, sync, call } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import UpgradeConfirmation from '@/components/account/UpgradeConfirmation'
  import UpgradeSuccess from '@/components/account/UpgradeSuccess'

  export default {
    components: {
      UpgradeConfirmation,
      UpgradeSuccess,
    },
    data() {
      return {
        upgradeCardTitleAndImageSrc: [
          {
            title: 'Get an extra product in every box',
            subtitle: 'Featuring new exclusive products each season, with $30+ value.',
            src: '/static/upgrade/AlltruePlusUpgradeProduct.gif',
          },
          {
            title: 'Enjoy $40 for the market',
            subtitle: '$10 credit per season to use in our Add-On Market or Essentials Shop.',
            src: '/static/upgrade/AlltruePlusUpgradeMoney.png',
          },
          {
            title: 'Score a members-only T-shirt',
            subtitle: 'One-time Welcome Gift &#8729; <br /> T&#8209;shirt Sizes: XS-XXXL',
            src: '/static/subscribe/tshirt-back.png',
          },
        ],
        confirmationModalOpen: false,
        upgradeSuccessfulModalOpen: false,
        upgradeSuccessfulTimeout: null,
        submitting: false,
        response: null,
        confirmed: false,
        error: null,
      }
    },
    computed: {
      ...get('subscribe', ['alltruePlusSeasonalUpgradeCost']),
      ...sync('subscribe', ['selectedPlanHandle']),
      screen,
      subscriptionId() {
        return this.$route.params.subscriptionId
      },
    },
    watch: {
      confirmed() {
        clearTimeout(this.upgradeSuccessfulTimeout)
        if (this.confirmed) {
          this.confirmationModalOpen = false
          this.error = null
          this.upgradeSuccessfulModalOpen = true
          this.selectedPlanHandle = 'alltruePlus'
          this.upgradeSuccessfulTimeout = setTimeout(() => {
            this.upgradeSuccessfulModalOpen = false
            if (this.$route.name === 'AccountAlltruePlusUpgrade') {
              this.$navigate({ name: 'AccountSubscribeTshirt' })
            } else {
              this.$navigate({
                name: 'SubscribeInsiders',
                params: { ...this.$route.params, subscriptionId: this.response.id },
              })
            }
          }, 3000)
        } else {
          this.upgradeSuccessfulModalOpen = false
        }
      },
    },
    methods: {
      ...call('subscribe', ['upgradeSubscription']),
      ...call('customize', ['load']),
      navigateToNextRoute() {
        if (this.$route.name === 'AccountAlltruePlusUpgrade') {
          this.$navigate({ name: 'AccountProfileSubscriptions' })
        } else {
          this.$navigate({
            name: 'SubscribeInsiders',
            params: { ...this.$route.params },
          })
        }
      },
      async upgradeImmediately() {
        this.submitting = true
        try {
          this.response = await this.upgradeSubscription({
            subscriptionId: this.subscriptionId,
          })
          await this.load()
          this.confirmed = true
        } catch (error) {
          this.error = error.data.message
        } finally {
          this.submitting = false
        }
      },
      dismissModal() {
        this.confirmationModalOpen = false
        this.error = null
      },
    },
  }
</script>

<template>
  <div>
    <!-- Hero -->
    <div class="w-full bg-black">
      <div class="text-dawn flex flex-col lg:grid hero-grid">
        <!-- Title/description/CTA -->
        <div
          class="flex flex-col justify-center xl:max-w-145 xl:ml-auto xl:mr-7 pl-10 pb-10 pt-6 2xl:pt-9 2xl:pb-12 xl:pl-0"
        >
          <span class="font-semibold text-md xl:text-lg"> Upgrade to Alltrue+ </span>
          <div
            class="font-semibold text-40px xl:text-54px mt-8 lg:mt-21px mb-10 lg:mb-30px"
            style="line-height: 1.1"
          >
            <span class="font-heading">
              Wait, want bonus products?
              <br v-if="!screen.lg" />
              Shopping credit? Wearable art?
            </span>
            <div class="text-lg lg:text-xl mt-4"> YOU GOT IT! </div>
          </div>
          <BaseButton color="spring" class="max-w-97 mr-10" @click="confirmationModalOpen = true">
            <span class="font-semibold text-xl leading-close">Upgrade to Alltrue+</span>
          </BaseButton>
        </div>
        <!-- Image -->
        <BaseImage
          :src="`/static/upgrade/AlltruePlusUpgrade${screen.lg ? 'Desktop' : 'Mobile'}.jpg`"
          alt=""
          responsive="lg"
          class="lg:pb-2px"
        />
      </div>
    </div>
    <div class="flex flex-col items-center px-13px md:px-11 lg:px-0">
      <span class="my-4 lg:my-10 text-md lg:text-lg leading-snug-tight text-center">
        Because you seem like a VIP, <br />
        check out the added perks of Alltrue+:
      </span>
      <div class="lg:mb-8">
        <div class="flex flex-col lg:flex-row lg:justify-center">
          <!-- Upgrade benefits cards -->
          <div
            v-for="({ title, src, subtitle }, cardIndex) in upgradeCardTitleAndImageSrc"
            :key="cardIndex"
            :class="`border-2 border-black bg-spring h-46 lg:h-99 w-76 2xs:w-87 lg:w-69 flex lg:flex-col items-center justify-between 2xs:pt-3 pb-3 lg:py-4  pl-4 2xs:pl-6 lg:px-6 ${
              cardIndex === 1 ? 'my-2 lg:my-0 lg:mx-55px' : ''
            }`"
            style="border-radius: 14px"
          >
            <UtilityConditionalWrapper
              :wrap="!screen.lg"
              tag="div"
              class="flex flex-col items-start justify-center h-full min-w-44 z-20"
            >
              <div
                class="border-2 border-black font-bold text-2xs lg:text-sm leading-close px-7px lg:px-9px py-2px lg:py-1"
                style="border-radius: 6px"
              >
                Alltrue+ Perks
              </div>
              <div
                :class="`max-w-48 lg:max-w-none font-heading font-semibold text-26px lg:text-4xl lg:text-center mt-2 lg:mt-0 flex items-end justify-center`"
                style="line-height: 1.07"
              >
                {{ title }}
              </div>
              <BaseRichText
                v-if="!screen.lg"
                font-size="2xs"
                :class="`mt-2 font-bold ${cardIndex === 0 ? 'max-w-51' : 'max-w-43'}`"
                v-html="subtitle"
              />
            </UtilityConditionalWrapper>
            <BaseImage
              :src="src"
              alt=""
              background-transparent
              responsive="sm"
              class="absolute lg:static right-0 mr-1 -ml-5 md:mx-0 max-w-30 2xs:max-w-37 lg:w-190px"
            />
            <BaseRichText
              v-if="screen.lg"
              font-size="2xs"
              class="font-bold text-center"
              v-html="subtitle"
            />
          </div>
        </div>
      </div>
      <BaseDivider v-if="!screen.lg" class="mt-4 mb-8 w-full" color="black" />
      <!-- Upgrade CTA button -->
      <button
        type="button"
        class="px-3 w-full rounded-full bg-black h-15 max-w-94 mx-auto flex items-center"
        @click="confirmationModalOpen = true"
      >
        <BaseButtonPill color="spring" height="tall" type="display">
          <span class="font-medium 2xs:mx-2">Upgrade</span>
        </BaseButtonPill>
        <span class="ml-4 w-2/3 font-medium text-2xs text-dawn">
          Upgrade to Alltrue+ to access <br v-if="screen['2xs']" />
          exclusive member benefits.
        </span>
      </button>
      <!-- Terms & conditions -->
      <p class="text-3xs text-left lg:text-center my-8 max-w-235">
        By clicking "Confirm & Upgrade Now", you authorize us to charge you the equivalent of
        {{ $formatPrice(alltruePlusSeasonalUpgradeCost) }}/season, plus tax if applicable, for the
        remaining quarterly seasons in your Membership (for example, if you have two seasons
        remaining on your current Annual Membership you will be charged
        {{ $formatPrice(alltruePlusSeasonalUpgradeCost * 2) }} immediately upon upgrade). Your
        upgrade to Alltrue+ will renew automatically at your next renewal and annually thereafter
        for {{ $formatPrice(alltruePlusSeasonalUpgradeCost * 4) }} along with your Membership at the
        full regular price using the payment method associated with your account, plus any
        applicable shipping charges and taxes. To prevent renewal, you can cancel in your account
        membership page, or by emailing us at
        <BaseLinkStyled font-weight="medium" href="mailto:help@alltrue.com">
          help@alltrue.com </BaseLinkStyled
        >. All prices listed are in USD.
      </p>
      <BaseDivider class="mb-8 w-full lg:w-245 xl:w-260" color="black" />
      <BaseLinkStyled color="black" font-size="xs" class="mb-18" @click="navigateToNextRoute">
        No thanks, maybe later
      </BaseLinkStyled>
    </div>

    <Portal to="modal">
      <UpgradeConfirmation
        v-if="confirmationModalOpen"
        :submitting="submitting"
        :error="error"
        type="alltruePlus"
        @upgrade="upgradeImmediately"
        @dismiss="dismissModal"
      />
      <UpgradeSuccess v-else-if="upgradeSuccessfulModalOpen" type="alltruePlus" />
    </Portal>

    <!-- Loading spinner -->
    <BaseSpinner
      v-if="submitting && !confirmationModalOpen"
      overlay="fixed"
      overlay-color="white"
      class="z-10"
    />
  </div>
</template>

<style lang="postcss" scoped>
  .hero-grid {
    @screen lg {
      grid-template-columns: 1fr 1fr;
    }
    @screen xl {
      grid-template-columns: 1.6fr 1.2fr;
    }
    @screen 2xl {
      grid-template-columns: 1.6fr 1.07fr;
    }
  }
</style>
